/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~swiper/css";
@import "~swiper/css/effect-flip";
@import "~swiper/css/pagination";
@import "~swiper/css/navigation";
@import "~swiper/css/autoplay";
@import "~swiper/css/effect-cube";


@import '~quill/dist/quill.core.css';
 @import '~quill/dist/quill.bubble.css';
 @import '~quill/dist/quill.snow.css';
 
ion-content {
    --background: var(--ion-color-primary);
    --color: white;
}


@font-face {
    font-family: BCBlack;
    src: url("./assets/fonts/BarlowCondensed-Black.ttf") format("truetype");
}

@font-face {
    font-family: BCBlackItalics;
    src: url("./assets/fonts/BarlowCondensed-BlackItalic.ttf") format("truetype");
}

@font-face {
    font-family: BCBold;
    src: url("./assets/fonts/BarlowCondensed-Bold.ttf") format("truetype");
}

@font-face {
    font-family: BCBoldItalics;
    src: url("./assets/fonts/BarlowCondensed-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: BCExtraBold;
    src: url("./assets/fonts/BarlowCondensed-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: BCExtraBoldItalics;
    src: url("./assets/fonts/BarlowCondensed-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: BCExtraLight;
    src: url("./assets/fonts/BarlowCondensed-ExtraLight.ttf") format("truetype");
}

@font-face {
    font-family: BCExtraLightItalics;
    src: url("./assets/fonts/BarlowCondensed-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
    font-family: BCItalics;
    src: url("./assets/fonts/BarlowCondensed-Italic.ttf") format("truetype");
}

@font-face {
    font-family: BCLight;
    src: url("./assets/fonts/BarlowCondensed-Light.ttf") format("truetype");
}

@font-face {
    font-family: BCLightItalics;
    src: url("./assets/fonts/BarlowCondensed-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: BCMedium;
    src: url("./assets/fonts/BarlowCondensed-Medium.ttf") format("truetype");
}